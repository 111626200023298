import {
  Button,
  Grid,
  Box,
  Typography,
  IconButton,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import UploadIcon from "../../../components/icons/UploadIcon";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import {
  FilePaginationItem,
  FileFolderItemResultResponse,
  FileFolderItemResult,
  FileParameter,
  Query,
} from "../../../api/fetch-client";
import { SnackbarContext } from "../../../context/SnackbarContext";
const useStyles = makeStyles((theme) => ({
  uploadBar: {
    bottom: "24px",
    right: "24px",
    left: "auto",
    zIndex: 1400,
    position: "fixed",
    borderRadius: "2px",
    border: "1px solid #ccc",
    width: "362px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  headerBg: {
    backgroundColor: "#f1f1f1",
  },
  header: {
    padding: "6px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  uploadBarHide: {
    display: "none",
    bottom: "24px",
    right: "24px",
    left: "auto",
    zIndex: 1400,
    position: "fixed",
    borderRadius: "2px",
    border: "1px solid #ccc",
    width: "362px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
}));
const Container = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: "${props => getColor(props)}",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
});
interface Props {
  handleClose?: any;
  type?: string;
  currentFolderId?: number;
  setFoldersFiles?: any;
  foldersFiles?: any;
  scroll?: any;
}
const AddFilesFolders: FC<Props> = ({
  handleClose,
  type,
  currentFolderId,
  setFoldersFiles,
  foldersFiles,
  scroll,
}) => {
  const classes = useStyles();
  const { setSnack } = useContext<any>(SnackbarContext);
  const { t } = useTranslation();
  const [uploadBar, setUploadBar] = useState<boolean>(false);
  const [progress, setProgress] = React.useState(0);
  const handleUploadClose = () => {
    setUploadBar(false);
  };
  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    fileRejections,
  } = useDropzone({
    // maxFiles: 5,
    maxFiles: 1,
    accept:
      "image/jpeg, image/png,image/jpg,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.txt,.rtf,.html,.zip,.mp3,.wma,.mpg,.flv,.avi,.mp4,.gif,.bmp,.pub,.accdb",
    //   "error": function(file, message, xhr) {
    //     if (xhr == null) this.removeFile(file); // perhaps not remove on xhr errors
    //     alert(message);
    //  }
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      console.log(acceptedFiles);
      if (acceptedFiles.length > 1) {
        setSnack({
          message: "You can't select more than 1 File",
          open: true,
          color: "error",
        });
      } else {
        setUploadBar(true);
        setProgress(0);
        let data: any = new FormData();
        let fileType: number = 0;
        let parentId: number | undefined = currentFolderId
          ? currentFolderId
          : undefined;
        data.append("name", acceptedFiles[0].name);
        data.append("FormFile", acceptedFiles[0]);
        data.append("fileType", fileType);
        let fileParm: FileParameter = {
          fileName: acceptedFiles[0].name,
          data: acceptedFiles[0],
        };

        //TODO get Current Folder ID as Parrent Id.
        Query.Client.saveFile(
          parentId,
          undefined,
          fileType,
          acceptedFiles[0].name,
          fileParm,
          undefined,
          (event: any) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
          }
        )
          .then((response: any) => {
            if (response.success) {
              console.log(response);
              let files: FileFolderItemResult | undefined = response.data;
              //TODO Refresh the current page
              setSnack({
                message: "File uploaded",
                open: true,
                color: "success",
              });
              setProgress(100);
              let updateFolder = {
                id: files?.id,
                name: files?.name,
                parentId: files?.parentId,
                downloadUrl: files?.downloadUrl,
                status: 0,
                type: 0,
                scroll: "true",
              };
              const _updateFolder = [...(foldersFiles ?? []), updateFolder];
              setFoldersFiles(_updateFolder);
              scroll(_updateFolder);
              handleClose();
            } else {
              setSnack({
                message: t(response.statusCode),
                open: true,
                color: "error",
              });
            }
          })
          .catch((error) => console.log("error", error));
      }
    }
  }, [acceptedFiles]);

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  useEffect(() => {
    fileRejections.map(({ file, errors }) =>
      errors.map((e) =>
        setSnack({
          message: "File not supported for upload",
          open: true,
          color: "error",
        })
      )
    );
  }, [fileRejections]);
  return (
    <>
      {type === "uploadButton" ? (
        <>
          <Button
            sx={{
              bgcolor: "primary.main",
              textTransform: "capitalize",
              color: "#fff",
              ":hover": {
                bgcolor: "primary.dark",
                color: "white",
              },
            }}
            {...getRootProps({
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
          >
            <input {...getInputProps()} />
            <AddIcon /> Add New File
          </Button>
          {acceptedFiles.length > 0 && (
            <Box
              className={uploadBar ? classes.uploadBar : classes.uploadBarHide}
            >
              <Box className={classes.headerBg}>
                <Box className={classes.header}>
                  <Typography>Upload</Typography>

                  <IconButton
                    sx={{ fload: "right" }}
                    onClick={handleUploadClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ backgroundColor: "#FFF" }}>
                <aside style={{ padding: "6px 16px" }}>
                  <ul
                    style={{
                      paddingLeft: "0px",
                      margin: "5px 0px 5px 0px",
                    }}
                  >
                    {acceptedFiles.map((file: any) => (
                      <li
                        key={file.path}
                        style={{
                          listStyle: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{file.path}</span>
                        <CircularProgressWithLabel value={progress} />
                      </li>
                    ))}
                  </ul>
                </aside>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="container" style={{ cursor: "pointer" }}>
                <Container
                  {...getRootProps({
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  })}
                >
                  <input {...getInputProps()} />
                  <Box sx={{ display: "flex" }}>
                    <p>
                      <UploadIcon />
                    </p>
                    <p>Use drag and drop to add minimum 1 file at time </p>
                  </Box>
                </Container>
              </div>
            </Grid>
          </Grid>
          {acceptedFiles.length > 0 && (
            <Box
              className={uploadBar ? classes.uploadBar : classes.uploadBarHide}
            >
              <Box className={classes.headerBg}>
                <Box className={classes.header}>
                  <Typography>Upload</Typography>

                  <IconButton
                    sx={{ fload: "right" }}
                    onClick={handleUploadClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ backgroundColor: "#FFF" }}>
                <aside style={{ padding: "6px 16px" }}>
                  <ul
                    style={{
                      paddingLeft: "0px",
                      margin: "5px 0px 5px 0px",
                    }}
                  >
                    {acceptedFiles.map((file: any) => (
                      <li
                        key={file.path}
                        style={{
                          listStyle: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {file.path} - {file.size} bytes
                        </span>

                        <CircularProgressWithLabel value={progress} />
                      </li>
                    ))}
                  </ul>
                </aside>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AddFilesFolders;
